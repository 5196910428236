.ag-header-cell {
    background-color: #0B96C1;
    font-size       : 14px;
    font-weight     : bold;
    color           : white;
}

.ag-icon-menu {
    color: black;
}

.ag-paging-panel.ag-unselectable {
    color: blue;
}

.ag-row-group {
    font-weight: bold;
    color      : brown !important;
}