.ag-theme-balham,
.ag-theme-balham-dark {
  -webkit-font-smoothing: antialiased !important;
  font-family           : "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-size             : 14px !important;
  font-weight           : 400;
  line-height           : 1.5;
  letter-spacing        : 0.00938em;
  align-items           : center;
  vertical-align        : middle;
}

.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
}

.ag-header-cell {
  /* background-color : #061c38ab !important; */
  background-color    : #f5f5f5 !important;
  font-family         : "Roboto", "Helvetica", "Arial", sans-serif !important;
  color               : #000 !important;
  font-size           : .875rem !important;
  font-weight         : 500 !important;
  letter-spacing      : .0071428571em !important;
  /* letter-spacing   : .5px !important; */
}

.ag-theme-balham-dark .ag-header-cell {
  background-color: #1e1e1e !important;
  color           : #FFF !important;
}


.ag-theme-balham .ag-paging-panel.ag-unselectable {
  background-color   : #f5f5f5 !important;
  color              : #005589 !important;
  /* flex-flow       : row-reverse !important; */
  /* color           : #6cfbcd !important;
  background-color   : #3f3f48 !important; */
}

.ag-theme-balham-dark .ag-paging-panel.ag-unselectable {
  background-color: #1e1e1e !important;
  color           : #03a9f4 !important;
}

/* putting in !important so it overrides the theme's styling as it hovers the row also */
.ag-theme-balham .ag-row-hover {
  background-color: rgba(0, 0, 0, .16) !important;
}

.ag-theme-balham .ag-row-odd-hover {
  background-color: rgba(0, 0, 0, 0.16) !important;
}

.ag-theme-balham .ag-row-even-hover {
  background-color: rgba(0, 0, 0, .16) !important;
}


.ag-theme-balham-dark .ag-root-wrapper {
  background-color: #1e1e1e !important;
}

.ag-theme-balham .ag-root-wrapper {
  background-color: #e7e8ea !important;
}



.ag-theme-balham-dark .ag-row-odd {
  background-color: #1e1e1e !important;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #292929 !important;
}


.ag-theme-balham-dark .ag-row-odd,
.ag-theme-balham-dark .ag-row-even,
.ag-theme-balham-dark .ag-cell {
  border-color: #2c2c2c !important;
}

/* 2px 1px #719ECE */
.ag-theme-balham .ag-cell-focus,
.ag-theme-balham-dark .ag-cell-focus {
  border-width          : 1px !important;
  border-color          : #006eff !important;
  box-shadow            : 0 0 12px 1.5px #006eff;
  border-radius         : 2px;
  background-color      : #006eff1f;
  /* color              : #ffffff; */
  /* border-color       : #0251b8 !important; 0 0 12px 0.5px #006eff; */
}

.no-cell-highlight.ag-cell-focus {
  border          : none !important;
  border-width    : 0px !important;
  border-color    : transparent !important;
  box-shadow      : 0 0 0 0 transparent !important;
  border-radius   : 0px !important;
  background-color: transparent !important;
}

.no-cell-highlight.ag-cell-focus>div.ag-react-container>span>img:active,
.no-cell-highlight.ag-cell-focus>div.ag-react-container>span>img:hover {
  border-width    : 1px !important;
  border-color    : #006eff !important;
  box-shadow      : 0 0 12px 1.5px #006eff;
  background-color: #006eff1f;
  border          : 1px solid #006eff;
}

.ag-icon-menu {
  color        : #aaaaaa98 !important;
  /* box-shadow: 0 0 12px 1.5px #006eff; */
}

.ag-header-icon,
.ag-icon-dsc,
.ag-icon-asc {
  color: #ff0088 !important;
}

.grid-date-picker input {
  height : 16px !important;
  padding: 0px !important;
}

.selected-row-class {
  background-color: #0066ff;
  color           : white;
}

/*-Text Alignments->>>*/
.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}

.left-text {
  text-align: left;
}

.justify-text {
  text-align: justify;
}

/*<<<<-Text Alignments*/