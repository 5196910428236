:root {
    --scrollbar-width: 15px;
    --scrollbar-track-color: #f2f2f2;
    --scrollbar-thumb-color: #888888;
    --scrollbar-thumb-hover-color: #555555;
}

.table-wrapper {
    max-height: 600px;
    overflow: auto;
}

.ftp-table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
}

.ftp-table th,
.ftp-table td {
    border: 1px solid #333;
    /* Darker border color */
    text-align: left;
    padding: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: 1px solid #444;
    /* Darker outline color */
}

.ftp-table th {
    background-color: #f2f2f2;
}

.table-wrapper::-webkit-scrollbar {
    width: var(--scrollbar-width);
}

.table-wrapper::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
}

.table-wrapper::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
    border-radius: 5px;
}

.table-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-hover-color);
}